<template>
    <div>
        <!-- 设置默认模板 -->
        <el-dialog title="小程序模板" :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showModelList"
            width="90%">
            <div v-loading="loading">
                <el-tabs v-model="modelListType" @tab-click="changeType">
                    <el-tab-pane label="社团端模板" v-if="type==='association'" name="own"></el-tab-pane>
                    <el-tab-pane label="园区端模板" v-if="type==='park'" name="own"></el-tab-pane>
                    <el-tab-pane label="名片端模板" v-if="type==='crm'" name="own"></el-tab-pane>
                    <el-tab-pane label="推荐模板" name="share"></el-tab-pane>
                </el-tabs>
                <div class="modelList d-flex flex-wrap-y" v-if="modelList && modelList.length > 0">
                    <div class="item" v-for="(row, index) in modelList" :key="index">
                        <img :src="row.coverImg" alt="">
                        <div class="operate flex-c-c">
                            <div>
                                <div>
                                    <el-button size="small" @click="showImgFun(row.coverImg)">在线预览</el-button>
                                </div>
                                <div class="mt10">
                                    <el-button type="primary" size="small" @click="useDragModel(row)">使用模板</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="textc mt20 " v-else style="font-size:16px;">当前没有可使用的小程序模板，请添加小程序模板</p>
                <br>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="showModelList = false">关 闭</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 图片放大 -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" width="400px" :visible.sync="showImg"
            class="xianshi" :destroy-on-close="true">
            <img draggable="false" style="width: 100%; margin: 20px 0px" :src="imgUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import { getDragPageAndModuleList } from "@/api/appModel";
export default {
    props: ['type', 'titleType', 'accountType', 'listId'],
    data() {
        return {
            showModelList: false,
            modelList: [],
            modelListType: "own",
            loading: false,
            showImg: false,
            imgUrl: "",
            shareData: [],//共享库的数据
            ownData: [],//自己的数据
        }
    },
    watch: {
        listId: {
            handler() {
                this.getDragPageAndModuleList();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        /**@method 使用模板 */
        useDragModel(model) {
            this.$emit("useDragModel", model)
        },
        /**@method 预览图片 */
        showImgFun(url) {
            this.showImg = true;
            this.imgUrl = url;
        },
        /**@method 切换模板数据 */
        changeType() {
            if (this.modelListType == 'own') {
                this.modelList = this.ownData;
            } else if (this.modelListType == 'share') {
                this.modelList = this.shareData;
            }
        },
        /**@method 获取模板数据 */
        getDragPageAndModuleList() {
            this.loading = true;
            this.modelList = [];
            let params = { isModel: 1, titleType: this.titleType, accountType: this.accountType }
            if (this.type == 'association') {
                params.associationId = this.listId;
            } else if (this.type == 'park') {
                params.parkId = this.listId;
            }else{
                params.companyId = this.listId;
            }
            getDragPageAndModuleList(params).then(res => {
                setTimeout(() => {
                    this.loading = false;
                }, 500)
                let shareData = [];
                let ownData = [];
                if (res.data) {
                    for (let row of res.data) {
                        if (this.type == 'association') {
                            if (row.associationId) {
                                ownData.push(row);
                            } else {
                                shareData.push(row);
                            }
                        } else if (this.type == 'park') {
                            if (row.parkId) {
                                ownData.push(row);
                            } else {
                                shareData.push(row);
                            }
                        } else {
                            if (!row.parkId && !row.associationId) {
                                shareData.push(row);
                            }
                        }
                    }
                    this.shareData = shareData;
                    this.ownData = ownData;
                    this.modelList = ownData;

                }
            }).catch(err => {
                setTimeout(() => {
                    this.loading = false;
                }, 500)
            })
        },
        /**@method 显示模板列表 */
        showModel() {
            this.getDragPageAndModuleList();
            this.showModelList = true;
        },
        /**@method 隐藏模板列表 */
        hideModel() {
            this.showModelList = false;
        },

    }
}
</script>
<style lang="scss">
.modelList .el-button {
    font-size: 14px;
    padding: 0 16px;
    height: 30px;

    &.el-button--primary {
        background: #51CDCB;
    }

    &.el-button--danger {
        background: red;
    }
}
</style>
<style lang="scss" scoped>
.modelList {
    margin: 10px 0;

    .item {
        position: relative;
        width: 200px;
        height: 430px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #dcdcdc;
        margin-left: 20px;
        margin-top: 20px;
        transition: linear 0.1s;
        overflow: hidden;

        img {
            width: 100%;
            min-height: 410px;
            border-radius: 5px;
        }

        .operate {
            display: none;
            text-align: right;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .item:hover {
        transform: scale(1.1);

        .operate {
            display: flex;
            background: rgba(0, 0, 0, 0.3);
        }
    }
}
</style>