<template>
    <div>
        <!-- 设置默认模板 -->
        <el-dialog :title="title" :close-on-click-modal="false" :modal-append-to-body="false"
            :visible.sync="showDefaultsModel" width="500px">
            <div v-loading="loading">
                <div class="flex-c-c">
                    <el-upload ref="uploadModel" :action="baseupload" class="defaultCoverImg" list-type="picture-card"
                        :limit="1" :on-preview="preview" :on-success="onSuccess" :on-error="uploadError"
                        :before-upload="uploads" :before-remove="handleRemove" :file-list="fileList"
                        :class="disabl ? 'disable' : ''">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <br>
                <span slot="footer" class="d-flex justify-content-end">
                    <el-button @click="showDefaultsModel = false">关 闭</el-button>
                    <el-button type="primary" @click="submitData()">{{ btnName }}</el-button>
                    <el-button type="success" icon="el-icon-upload" title="自动生成效果图" @click="automatically()"></el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 图片放大 -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" width="400px" :visible.sync="dialogVisibles"
            class="xianshi" :destroy-on-close="true">
            <img draggable="false" style="width: 100%; margin: 20px 0px" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['imgUrl', 'title', 'btnName'],
    data() {
        return {
            //设置为默认操作配置
            fileList: [],
            showDefaultsModel: false,
            dialogVisibles: false, // 放大的图片
            dialogImageUrl: '', // 上传的图片
            loading: false,
        }
    },
    watch: {
        imgUrl: {
            deep: true,
            immediate: true,
            handler(url) {
                if (url) {
                    this.dialogImageUrl = url;
                    this.fileList = [{ url: url }]
                } else {
                    this.dialogImageUrl = '';
                    this.fileList = []
                }
            },
        }
    },
    computed: {
        // 提交按钮是否可以点击
        disabl() {
            if (this.dialogImageUrl) return true
            return false
        },
        baseupload() {
            return `${this.$store.state.uploadingUrl}`
        },
    },
    methods: {
        /**@method 显示添加模板 */
        showModel() {
            this.dialogImageUrl = '';
            this.fileList = []
            this.showDefaultsModel = true;
        },
        /**@method 隐藏添加模板 */
        hideModel() {
            this.showDefaultsModel = false;
        },
        /**@method 自动生成效果图回调 */
        automatically() {
            this.loading = true;
            this.$emit("automatically")
        },
        /**@method 提交回调*/
        submitData() {
            let formData = {
                coverImg: this.dialogImageUrl
            };
            this.$emit("submitData", formData)
        },
        // 删除图片
        handleRemove() {
            this.dialogImageUrl = ''
            return true
        },
        // 预览
        preview() {
            this.dialogVisibles = true
        },
        // 上传成功
        onSuccess(response) {
            // 返回错误
            if (!response.data)
                return this.$message.error('上传图片失败，请删除后重新上传')
            this.dialogImageUrl = this.$store.state.ossUrl + response.data;
            this.$emit("uploadImg",this.$store.state.ossUrl + response.data)
        },
        // 上传前
        uploads(file) {
            if (!file.type.includes('image')) {
                this.$message.error('请上传图片！')
                return false
            }
        },
        // 上传失败
        uploadError() {
            this.$message.error('请重新上传')
        },
    }
}
</script>

<style lang="scss">
.defaultCoverImg .el-upload--picture-card {
    width: 200px !important;
    height: 400px !important;
    line-height: 400px !important;
}

.defaultCoverImg img {
    height: auto !important;
    min-height: 400px !important;
}

.defaultCoverImg .el-icon-upload-success {
    position: absolute;
    top: 11px;
    left: 14px;
    margin: 0 !important;
}

.defaultCoverImg .el-upload-list--picture-card .el-upload-list__item {
    width: 200px !important;
    height: 400px !important;
    line-height: 400px !important;
}

.defaultCoverImg.disable .el-upload {
    display: none !important;

}
</style>